.confirm-modal .modal-header {
  color: #4d6366;
  font-weight: bold; }

.confirm-modal .modal-body {
  text-align: left; }

.confirm-modal .btn {
  position: relative;
  height: 46px;
  border-radius: 4px;
  font-weight: bold;
  width: 106px;
  background-color: #dbe3e7;
  color: #303738;
  border: 1px solid #dbe3e7; }

.confirm-modal .btn:hover {
  background-color: #31545e;
  border: 1px solid #31545e;
  color: #fff; }

.confirm-modal .confirm-btn {
  margin-left: 1rem; }

.confirm-modal .modal-footer {
  border-top: 0; }
