.react-tiny-popover-container {
  z-index: 1; }
  .react-tiny-popover-container .user-actions {
    height: 85px;
    width: 200px; }
  .react-tiny-popover-container .dropdown-menu {
    top: 0px !important;
    border: 1px solid #E8E8E8 !important; }
  .react-tiny-popover-container .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.user-action-btn {
  font-size: 1.5rem;
  position: absolute;
  top: 0; }
