@keyframes brawer-bounceOut {
  0% {
    opacity: 1; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes brawer-bounceIn {
  0% {
    opacity: 0; }
  60% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.Drawer {
  position: fixed;
  overflow-y: scroll;
  left: calc(100%);
  width: 500px;
  max-width: 750px;
  min-width: 250px;
  top: 0px;
  bottom: 0;
  transition: left 0.25s;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 1rem;
  z-index: 100;
  opacity: 0; }
  .Drawer.is-open {
    left: calc(100% - 500px);
    z-index: 100;
    animation-duration: 0.25s;
    animation-name: brawer-bounceIn;
    opacity: 1; }
  .Drawer.is-close {
    animation-duration: 0.25s;
    animation-name: brawer-bounceOut;
    opacity: 0; }
  .Drawer .close {
    margin: 10px 0 0 0;
    padding: 5px 10px;
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 1; }
