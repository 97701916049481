@media (min-width: 768px) {
  .summary-report-page .container {
    max-width: 1100px; } }

.summary-report-page .label {
  color: #4d6366;
  font-weight: 600; }

.summary-report-page .alert-warning-color {
  color: #856404 !important; }

.summary-report-page .alert-warning-title {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1rem; }

.summary-report-page .btn-container {
  margin-top: 50px;
  width: 100%;
  position: fixed;
  z-index: 99;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.3rem;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.15); }
  .summary-report-page .btn-container .btn {
    min-width: 120px;
    margin: 0px 5px; }
  .summary-report-page .btn-container .btn-reject,
  .summary-report-page .btn-container .btn-accept {
    border-radius: 6px;
    float: right;
    right: 7.5rem;
    bottom: 1.1rem;
    height: 2.8rem;
    padding: 0 1.5rem;
    margin-top: 24px; }
  .summary-report-page .btn-container .btn-accept {
    background-color: #36a6b3;
    color: #fff; }
  .summary-report-page .btn-container .btn-reject {
    border: 2px solid #36a6b3; }
  .summary-report-page .btn-container .bold-text {
    color: #4d6366;
    font-weight: 600; }

.summary-report-page .bg-lightTeal {
  background-color: rgba(54, 166, 179, 0.1); }

.summary-report-page .summary-main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #f5f8f9; }

.summary-report-page .step-wrapper {
  height: 100%;
  overflow: scroll; }

.summary-report-page .step-page {
  margin: 1rem auto;
  padding: 1.5rem; }

.summary-report-page .page-title {
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 600;
  text-align: left; }

.summary-report-page .page-note {
  font-size: 1.5rem;
  text-align: left; }

.summary-report-page .success-message,
.summary-report-page .error-message {
  margin: 1rem;
  position: absolute;
  z-index: 99;
  left: 7.5rem;
  right: 7.5rem;
  width: auto; }

.summary-report-page .error-message .title {
  color: #fff; }

.summary-report-page .checkout-and-pay {
  background-color: #fff;
  margin-bottom: 0 !important; }

.summary-report-page .alert-container {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  min-width: 300px;
  width: 45% !important;
  margin: auto !important; }
  .summary-report-page .alert-container .title.alert-danger {
    background-color: #ed5f3b;
    border: 0;
    border-radius: 0; }
  .summary-report-page .alert-container .title .close {
    color: #fff; }
    .summary-report-page .alert-container .title .close:hover {
      cursor: pointer; }
  .summary-report-page .alert-container .message {
    padding: 0.5rem 1rem;
    font-size: 1rem; }

.summary-report-page .summary-info .row {
  margin-bottom: 1rem; }

.summary-report-page .summary-info .label {
  margin: 0 !important;
  color: #4d6366;
  font-weight: 700; }

.summary-report-page .summary-info .value {
  color: #4d6366;
  font-size: 0.9rem;
  text-align: right; }

.summary-report-page .worked-summary .lang-summary-table .header-cell {
  text-transform: initial; }

.summary-report-page .summary-container {
  margin-top: 2rem; }
  .summary-report-page .summary-container .summary .title {
    color: #4d6366;
    font-size: 1.2rem;
    font-weight: bold; }
  @media (min-width: 768px) {
    .summary-report-page .summary-container .summary-info-col {
      padding-left: 2rem; } }

.summary-report-page .user-profile .label {
  color: #4d6366;
  font-weight: 600; }

.summary-report-page .user-profile .value {
  margin-bottom: 15px;
  color: #4d6366;
  font-size: 0.9rem; }

.summary-report-page .user-profile .text-btn {
  color: #36a6b3;
  font-weight: 500;
  font-size: 14px; }

.summary-report-page .payment-summary {
  margin-bottom: 2rem; }
  .summary-report-page .payment-summary hr {
    margin: 0.5rem 0; }
  .summary-report-page .payment-summary .bold-1 {
    color: #4d6366 !important;
    font-weight: 600; }
  .summary-report-page .payment-summary .bold-2 {
    font-size: 1.25rem;
    color: #4d6366 !important;
    font-weight: 600; }
  .summary-report-page .payment-summary .label {
    display: inline-block;
    width: 35%;
    color: #a1b0b3;
    font-weight: 600;
    margin: 0.5rem 0; }
  .summary-report-page .payment-summary .value {
    display: inline-block;
    width: 65%;
    text-align: right; }
    .summary-report-page .payment-summary .value.bold {
      font-size: 1.5rem;
      color: #36a6b3;
      font-weight: 600; }

.summary-report-page .total-payed .label {
  font-size: 1.2rem; }

.summary-report-page .content-container {
  width: 100%;
  margin: 20px 0; }
  .summary-report-page .content-container.box {
    border: 1px solid #d5dbdc;
    box-sizing: border-box; }

.summary-report-page .payment-info .required-text {
  color: #cc5050;
  font-size: .75rem;
  font-style: italic;
  text-align: right; }

.summary-report-page .payment-info .note-guide {
  color: #829799;
  font-weight: 400i;
  font-style: italic;
  font-size: 0.75rem; }

.summary-report-page .payment-info textarea {
  width: 100%; }

.summary-report-page .payment-info .sign-in-form .submit-btns {
  margin-top: 2rem;
  text-align: right; }
  .summary-report-page .payment-info .sign-in-form .submit-btns button {
    float: right;
    width: 200px; }

.summary-report-page .payment-info .sign-in-form .alert {
  top: 38px;
  left: 6rem;
  right: 2.25rem; }

.summary-report-page .payment-info .sign-in-message {
  color: #4d6366;
  font-weight: 600;
  margin-bottom: 1rem; }

.summary-report-page .payment-info .sign-up-help {
  line-height: 50px;
  color: #4d6366;
  font-size: 14px; }
  .summary-report-page .payment-info .sign-up-help .btn-text {
    color: #36a6b3;
    font-weight: 500; }

.summary-report-page .payment-info .form-label {
  color: #6a7d80; }

.summary-report-page .text-success {
  text-align: center;
  font-size: 2rem;
  color: #36a6b3 !important;
  float: right;
  margin: 0 !important;
  position: absolute;
  right: 7.5rem;
  bottom: 1.5rem;
  height: 2.8rem;
  padding: 0 1.5rem; }
  .summary-report-page .text-success i {
    font-size: 1.5rem; }

.summary-report-page .text-fail {
  text-align: center;
  font-size: 2rem;
  color: #ed5f3b !important;
  float: right;
  margin: 0 !important;
  position: absolute;
  right: 7.5rem;
  bottom: 1.5rem;
  height: 2.8rem;
  padding: 0 1.5rem; }
  .summary-report-page .text-fail i {
    font-size: 1.5rem; }

.summary-report-page .checkout .btn {
  position: fixed;
  margin: 0 !important;
  right: 7.5rem;
  bottom: 1.1rem;
  height: 2.8rem;
  padding: 0 1.5rem;
  color: #fff;
  border-radius: 4px;
  background-color: #36a6b3;
  border: 1px solid #36a6b3;
  z-index: 100;
  min-width: 120px;
  line-height: 43px; }

.summary-report-page .checkout .paypal-button {
  position: absolute;
  left: -1px;
  right: -1px;
  width: auto !important;
  top: -2px;
  bottom: -2px;
  opacity: 0.0001; }

.summary-report-page .header,
.summary-report-page .footer {
  text-align: center;
  padding: 1rem;
  color: #6a7d80; }

.summary-report-page .footer {
  margin-bottom: 135px;
  font-size: 14px; }
  .summary-report-page .footer .contact-info {
    font-size: 1rem;
    margin-bottom: 8px; }

.summary-report-page .note-text {
  color: #829799;
  font-size: 0.8rem; }

.summary-report-page .order-notes pre {
  white-space: pre-wrap;
  line-height: 1.8; }

.payment-success-page .header {
  text-align: center;
  padding: 1rem;
  color: #829799;
  font-size: 0.8rem; }

.payment-success-page .paid-successfully {
  top: 90px !important; }

.payment-success-page .paid-successfully {
  margin-top: 0 !important;
  top: 74px !important; }
  .payment-success-page .paid-successfully .step-page {
    background-color: #fff;
    text-align: center;
    padding: 3.75rem 0; }
  .payment-success-page .paid-successfully .icon-done {
    width: 100px;
    height: 100px;
    background: url("/img/icon_done.svg") no-repeat;
    background-size: contain;
    display: inline-block; }
  .payment-success-page .paid-successfully .page-title {
    padding-top: 1.5rem; }
  .payment-success-page .paid-successfully .page-note {
    margin: 1.5rem 0 3rem 0; }
  .payment-success-page .paid-successfully button {
    padding-top: 1.5rem;
    color: #fff !important;
    border-radius: 4px;
    background-color: #36a6b3;
    margin: 0 !important;
    height: 2.8rem;
    padding: 0 1.5rem; }

.bold {
  font-weight: 600;
  color: #4d6366; }
