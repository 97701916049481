.assignment-maybe {
  overflow: hidden;
  font-size: 1.5rem; }
  .assignment-maybe .btn {
    margin-top: 1rem;
    min-width: 110px;
    min-height: 40px;
    border: 1px solid #1b7680 !important;
    border-radius: 4px;
    border-color: #36a6b3 !important;
    background-color: #36a6b3 !important;
    color: #fff !important; }
