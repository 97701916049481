.button-select-item {
  min-width: 115px;
  line-height: 40px;
  border: 1px solid #A1B0B3;
  text-align: center;
  border-radius: 30px;
  display: inline-block;
  background-color: #fff;
  margin: 0 5px;
  padding: 0 20px; }
  .button-select-item.selected {
    background-color: #36A6B3;
    border-color: #36A6B3;
    color: #fff; }
