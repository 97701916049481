.order-list {
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  .order-list .header .filter-btn {
    margin-top: auto;
    margin-bottom: auto;
    color: #36a6b3; }
    .order-list .header .filter-btn i {
      color: initial; }
    .order-list .header .filter-btn .icon-clear-filter {
      margin: 0 0.2rem; }
  .order-list .Table {
    font-size: 0.9rem; }
    .order-list .Table.public_fixedDataTable_main {
      border: 0; }
    .order-list .Table .public_fixedDataTable_header {
      border-bottom: 2px solid #364041;
      color: #303738; }
      .order-list .Table .public_fixedDataTable_header .public_fixedDataTableCell_main {
        background-color: #fff !important;
        background-image: none; }
    .order-list .Table .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
      background-color: #E4E4E6;
      border-bottom: 1px solid #dadada;
      border-right: 0; }
  .order-list .action-btns {
    font-size: 1.3rem;
    margin-top: -0.2rem; }
    .order-list .action-btns .btn {
      font-size: inherit;
      padding: 0;
      color: #6A7276;
      opacity: 1 !important; }
      .order-list .action-btns .btn.done {
        color: #61BD55; }
      .order-list .action-btns .btn.disabled {
        opacity: 0.4 !important;
        cursor: default; }

.Drawer.filter-tray {
  top: 150px;
  bottom: 0px; }

.expanded-content {
  font-size: 0.9rem; }
  .expanded-content .list-container {
    overflow: auto; }
  .expanded-content .Table .public_fixedDataTable_header {
    border-bottom: 1px solid #364041; }
  .expanded-content .Table .public_fixedDataTableRow_even .public_fixedDataTableCell_main {
    background-color: #fff; }
  .expanded-content .Table .public_fixedDataTableRow_odd .public_fixedDataTableCell_main {
    background-color: #fff; }
  .expanded-content .Table .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
    border-bottom: 1px solid #DBE3E7;
    border-right: 0; }
  .expanded-content .Table .public_fixedDataTable_bodyRow .public_fixedDataTableCell_cellContent {
    padding: 4px 8px; }
