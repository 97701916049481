.popover-content-large {
  width: 4px;
  height: 0px; }
  .popover-content-large .content {
    z-index: 100;
    top: 8px;
    left: -126px;
    position: absolute;
    max-width: 190px; }
    .popover-content-large .content input {
      width: 256px;
      height: 40px; }
    .popover-content-large .content.empty {
      color: #6a7276; }
  .popover-content-large .overlay {
    z-index: 99; }
  .popover-content-large .react-datepicker-popper {
    min-width: 420px; }

.react-tiny-popover-container {
  overflow: visible;
  z-index: 9999; }

.assignment-maybe .selection {
  background-color: #eee;
  border: 2px solid #eee;
  border-radius: 1rem;
  padding: 0.2rem 0;
  white-space: nowrap; }

.assignment-maybe .date-time-input .content {
  min-width: 252px;
  display: inline-block;
  padding: 0 1rem; }
  .assignment-maybe .date-time-input .content.empty {
    color: #6a7276; }

.assignment-maybe .date-time-input i {
  margin-right: 5px; }
