.page-container .list-container {
  flex: 1 1 auto;
  height: 100%;
  overflow: scroll; }

.checklists .filter-btn {
  position: relative !important;
  margin-top: auto;
  margin-bottom: auto;
  top: unset !important;
  right: unset !important; }

.user-list {
  flex: 1 1;
  display: flex;
  flex-direction: column; }
  .user-list .header {
    position: relative; }
    .user-list .header .filter-btn {
      position: absolute;
      right: 1rem;
      top: 2rem;
      color: #36a6b3; }
      .user-list .header .filter-btn i {
        color: initial; }
      .user-list .header .filter-btn .icon-clear-filter {
        margin: 0 0.2rem; }
  .user-list .Table {
    font-size: 0.9rem; }
    .user-list .Table .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
      border-bottom: 0;
      border-right: 0; }
    .user-list .Table.public_fixedDataTable_main {
      border: 0; }
    .user-list .Table .public_fixedDataTable_header {
      border-bottom: 2px solid #364041;
      color: #303738; }
      .user-list .Table .public_fixedDataTable_header .public_fixedDataTableCell_main {
        background-color: #fff !important;
        background-image: none; }
  .user-list .add-btn {
    position: absolute;
    top: -1rem;
    right: 0;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff !important;
    padding-left: 40px;
    background-color: #31545e;
    width: 150px;
    font-weight: 600; }
    .user-list .add-btn i {
      font-size: 1.2rem;
      position: absolute;
      left: 20px;
      top: 10px; }

.Drawer.filter-tray {
  top: 152px;
  bottom: 0px; }
