.text-input textarea,
.text-input input {
  min-width: 300px;
  padding: 0 5px; }

.text-input textarea {
  resize: both; }

.text-input .input-state {
  position: absolute;
  right: 0px;
  top: -20px;
  font-size: 0.8rem;
  opacity: 0.4;
  color: #007bff; }
  .text-input .input-state.error {
    color: #ed5f3b; }
