.avatar {
  background-color: lightgray;
  color: white;
  border-radius: 50%;
  min-width: 2rem;
  min-height: 2rem;
  max-width: 20rem;
  max-height: 20rem;
  position: relative; }
  .avatar .avatar-short-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block; }
  .avatar.avatar-sm {
    width: 16px;
    height: 16px;
    margin-right: 10px; }
  .avatar.avatar-md {
    width: 32px;
    height: 32px;
    margin-right: 16px; }

.qanda {
  z-index: 99; }
  .qanda .qanda-header {
    z-index: 100; }
  .qanda .qanda-list .qanda-list-top {
    z-index: 100; }
  .qanda .qanda-list .ReactVirtualized__List {
    outline: none; }
    .qanda .qanda-list .ReactVirtualized__List .question .question-resolved:after {
      font-family: "Font Awesome 5 Free";
      content: "\f560";
      font-weight: 900;
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 0.6rem; }
    .qanda .qanda-list .ReactVirtualized__List .question:hover .reaction-commands {
      visibility: visible; }
    .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands {
      visibility: hidden;
      height: 38px;
      right: 20px;
      margin-top: -22px;
      background: white;
      border-radius: 19px;
      border: 1px solid #dfdfdf; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands .btn {
        border: none;
        padding: .25rem 1rem; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands :last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands :first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px; }
    .qanda .qanda-list .ReactVirtualized__List .question .comment .thread-comment {
      margin: auto 0; }
    .qanda .qanda-list .ReactVirtualized__List .question pre {
      white-space: pre-wrap; }
  .qanda .qanda-list .avatar-icon {
    margin-right: 0.5rem; }
  .qanda .qanda-list .author {
    font-weight: bold;
    margin-right: 0.5rem; }
  .qanda .qanda-list .comment-input {
    line-height: 40px; }
    .qanda .qanda-list .comment-input .text-input {
      display: inline-block;
      width: auto; }
  .qanda .qanda-list .card-body {
    padding: 0.5rem 1rem 1rem; }
  .qanda input {
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s; }

.text-input {
  position: relative; }
  .text-input .clear-icon {
    position: absolute;
    text-shadow: 0 1px 0 #fff;
    opacity: .4;
    right: 8px;
    top: 50%;
    margin-top: -8px; }
    .text-input .clear-icon:hover {
      opacity: .75; }
  .text-input .spinner-wrapper .fa {
    font-size: 1rem;
    margin-top: -0.5rem !important;
    right: 0.5rem;
    left: initial;
    color: #cecece; }

.spinner-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 100; }
  .spinner-wrapper .fa {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    margin-top: -35px !important;
    font-size: 50px; }

.qna-icon-wrapper {
  position: fixed;
  height: inherit;
  bottom: 0;
  left: calc(100% - 90px); }
  @media (min-width: 1720px) {
    .qna-icon-wrapper {
      left: calc(50% + 800px - 15px); } }

@keyframes qna-icon-open {
  0% {
    background-image: url("/img/icon_qna_to_close.png"); }
  60% {
    background-image: url("/img/icon_qna_animation.png"); }
  100% {
    background-image: url("/img/icon_qna_to_open.png"); } }

@keyframes qna-icon-close {
  0% {
    background-image: url("/img/icon_qna_to_open.png"); }
  60% {
    background-image: url("/img/icon_qna_animation.png"); }
  100% {
    background-image: url("/img/icon_qna_to_close.png"); } }

.qna-icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  box-shadow: inset 0 0 0 0 #d5dbdc;
  border-radius: 100%; }
  .qna-icon.to-close {
    animation-duration: 0.2s;
    animation-name: qna-icon-close;
    background-image: url("/img/icon_qna_to_close.png"); }
  .qna-icon.to-open {
    animation-duration: 0.2s;
    animation-name: qna-icon-open;
    background-image: url("/img/icon_qna_to_open.png"); }

.qna-tray {
  top: 68px;
  bottom: 80px;
  padding: 1rem 0 0; }
  .qna-tray .bg-light {
    background-color: #ececec !important; }
  .qna-tray .section-title {
    color: #303738;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem; }
  .qna-tray .close {
    z-index: 100; }
  .qna-tray .qna-container {
    z-index: 1;
    padding: 0; }
    .qna-tray .qna-container .qanda .qanda-list .card-body.question-resolved::after {
      color: var(--green); }
  .qna-tray .qanda-list-top .form-group {
    margin: 2rem 0; }
  .qna-tray .qanda-header {
    margin-bottom: 1rem; }
  .qna-tray .qanda-group {
    left: 1rem !important;
    right: 1rem;
    width: auto !important; }
    .qna-tray .qanda-group .qanda-group-content {
      margin: 1rem 0;
      padding: 1rem;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 4px; }
  .qna-tray .qanda-group-body {
    margin: 1rem 0; }
  .qna-tray .card-title {
    font-weight: bold;
    color: #303738;
    margin: 1rem 0 0; }
  .qna-tray .card {
    margin-top: 2rem; }
  .qna-tray .card-subtitle {
    display: inline-block;
    color: #303738;
    background-color: #efefef;
    border-radius: 14px;
    padding: 4px 14px; }
  .qna-tray .author {
    color: #303738; }
  .qna-tray .comment {
    font-size: 0.9rem; }
  .qna-tray .thread-comment textarea {
    resize: none; }
  .qna-tray .thread-comment pre {
    display: inline-block !important;
    font-size: inherit !important;
    color: inherit !important;
    border: initial !important;
    padding: initial !important;
    margin: inherit !important;
    font-family: inherit !important;
    overflow: inherit !important; }
  .qna-tray .question-new .form-group {
    margin: 1.5rem 0rem;
    font-size: 0.9rem; }
    .qna-tray .question-new .form-group label {
      font-weight: bold; }
  .qna-tray .btn-primary {
    background-color: #31545e;
    border-color: #31545e;
    font-size: 0.9rem;
    font-weight: bold;
    height: 40px;
    min-width: 100px; }
    .qna-tray .btn-primary i {
      float: left;
      line-height: 22px; }
    .qna-tray .btn-primary:hover {
      background-color: #343a40;
      border-color: #343a40; }
    .qna-tray .btn-primary:disabled {
      background-color: #31545e;
      border-color: #31545e;
      opacity: 0.75; }
  .qna-tray .btn-container {
    margin-top: 1rem; }
