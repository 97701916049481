.user {
  background-color: #eee !important;
  border: 2px solid #eee !important;
  border-radius: 1rem;
  padding: 0.2rem 1rem !important;
  white-space: nowrap !important; }
  .user.active {
    background-color: #007bff !important; }
  .user.selected {
    background-color: #fff !important;
    box-shadow: 0 0 5px #5e9ed6;
    border: 2px solid #5e9ed6 !important; }
  .user .content-empty {
    color: #6A7276; }

.icon-selected {
  display: none; }

.user-select {
  max-height: 300px;
  position: relative; }
  .user-select .dropdown-menu {
    position: relative !important;
    padding: 1rem 1rem 0 1rem;
    max-height: 100%; }
  .user-select .menu-items-container {
    overflow: scroll;
    max-height: 240px; }
    .user-select .menu-items-container .dropdown-item {
      margin-bottom: 1rem;
      overflow: hidden; }
      .user-select .menu-items-container .dropdown-item i {
        margin-right: 10px; }
  .user-select .search-input {
    position: relative;
    margin-bottom: 1rem; }
    .user-select .search-input i {
      position: absolute;
      color: #a1b0b3;
      font-size: 1rem;
      left: 8px;
      top: 3px; }
    .user-select .search-input input {
      border: 1px solid #d5dbdc;
      border-radius: 4px;
      padding-left: 2rem;
      height: 34px; }
  .user-select .user:hover {
    background-color: #36a6b3 !important;
    color: #fff; }
