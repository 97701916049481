.popover-content {
  width: 4px;
  height: 0px; }
  .popover-content .content {
    z-index: 100;
    left: -87px;
    position: absolute;
    max-width: 190px; }
    .popover-content .content input {
      width: 180px;
      height: 37px; }
    .popover-content .content.empty {
      color: #6A7276; }
  .popover-content .overlay {
    z-index: 99; }
  .popover-content .react-datepicker-popper {
    min-width: 420px; }

.react-tiny-popover-container {
  overflow: visible !important;
  z-index: 9999; }

.selection {
  background-color: #eee !important;
  border: 2px solid #eee !important;
  border-radius: 1rem;
  padding: 0.2rem 0 !important;
  white-space: nowrap; }

.date-time-input .content {
  min-width: 178px;
  display: inline-block;
  padding: 0 1rem !important; }
  .date-time-input .content.empty {
    color: #6A7276; }

.date-time-input i {
  margin-right: 5px; }
