.orders-filter-form {
  height: 100%;
  position: relative;
  width: 100%; }
  .orders-filter-form form {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 3.5rem;
    min-height: 550px; }
  .orders-filter-form .title {
    color: #4d6366;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem; }
  .orders-filter-form .form-label {
    padding: 10px 0 0;
    display: block;
    font-weight: 600; }
    .orders-filter-form .form-label span {
      color: #829799;
      font-size: 12px;
      font-style: italic;
      font-weight: initial; }
  .orders-filter-form .row:first-child {
    margin-top: 1rem; }
  .orders-filter-form .form-group {
    width: 100%; }
  .orders-filter-form .form-input {
    height: 40px;
    width: 100%;
    border: 1px solid #829799;
    border-radius: 4px;
    padding: 0 0.5rem; }
  .orders-filter-form .submit-btns {
    position: absolute;
    bottom: 0;
    text-align: right;
    width: 100%; }
    .orders-filter-form .submit-btns button {
      height: 46px;
      width: 100px;
      border-radius: 4px;
      background-color: #36a6b3;
      color: #fff;
      font-weight: 500;
      margin-left: 1rem; }
