.progress {
  background-color: transparent !important;
  border: solid 1px #a1b0b3; }
  .progress div {
    height: 1rem;
    font-size: 0.6rem; }
  .progress .progress-bar {
    color: #a1b0b3; }
    .progress .progress-bar.bg-transparent {
      background-color: transparent; }
