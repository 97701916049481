.order-details {
  flex: 1 1; }
  .order-details .target-lang-table {
    margin-top: 2rem; }
    .order-details .target-lang-table .Table {
      font-size: 0.9rem; }
      .order-details .target-lang-table .Table.public_fixedDataTable_main {
        border: 0; }
      .order-details .target-lang-table .Table .public_fixedDataTable_bodyRow .public_fixedDataTableCell_cellContent {
        position: relative; }
      .order-details .target-lang-table .Table .public_fixedDataTable_header {
        border-bottom: 1px solid #364041;
        color: #303738; }
        .order-details .target-lang-table .Table .public_fixedDataTable_header .public_fixedDataTableCell_main {
          background-color: #fff !important;
          background-image: none; }
      .order-details .target-lang-table .Table .public_fixedDataTableRow_even .public_fixedDataTableCell_main {
        background-color: #fff; }
      .order-details .target-lang-table .Table .public_fixedDataTableRow_odd .public_fixedDataTableCell_main {
        background-color: #fff; }
      .order-details .target-lang-table .Table .fixedDataTableRowLayout_rowWrapper .public_fixedDataTableCell_main {
        border-bottom: 1px solid #dbe3e7;
        border-right: 0; }
      .order-details .target-lang-table .Table .right-cell {
        float: right;
        text-align: right; }
  .order-details .date-time {
    padding: 0.2rem 1rem !important; }
    .order-details .date-time i {
      margin-right: 0.5rem; }
  .order-details .file-download {
    -ms-word-break: break-all;
    word-break: break-word;
    word-wrap: break-word; }
  .order-details .merge-btn i,
  .order-details .deliver-btn i {
    margin-right: 0.5rem; }
  .order-details .deliver-btn {
    color: #fff !important;
    background-color: #31545e; }
  .order-details .btn.disabled {
    opacity: 0.4 !important; }
  .order-details .action-btns {
    font-size: 1.3rem; }
    .order-details .action-btns .upload-icon,
    .order-details .action-btns button {
      display: inline-block; }
    .order-details .action-btns label {
      margin-bottom: 0; }
    .order-details .action-btns .btn {
      font-size: inherit;
      padding: 0;
      margin-right: 1rem;
      color: #6a7276; }
      .order-details .action-btns .btn.done {
        color: #61bd55; }
    .order-details .action-btns .btn:last-child {
      margin-right: 0;
      margin-left: 1rem; }
  .order-details .alert-container {
    background-color: #fff; }
    .order-details .alert-container .title {
      color: #fff;
      width: 100%; }
      .order-details .alert-container .title.alert-danger {
        background-color: #ed5f3b;
        border: 0;
        border-radius: 0; }
      .order-details .alert-container .title .close {
        color: #fff; }
    .order-details .alert-container .message {
      padding: 0.5rem 1rem; }
  .order-details .alert-success i {
    margin-right: 0.5rem; }
  .order-details .alert-container,
  .order-details .alert-success {
    border-radius: 6px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    font-size: 14px;
    min-width: 300px;
    width: 45% !important;
    margin: auto !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 68px;
    z-index: 9; }
  .order-details .trans-request {
    padding-bottom: 1rem; }
  .order-details .pm-notes {
    padding-bottom: 1rem; }
    .order-details .pm-notes textarea {
      width: 100%;
      max-width: 100%;
      border: 1px solid #a1b0b3; }
  .order-details .note-review {
    white-space: break-spaces; }
  .order-details .game-infos .row {
    margin-bottom: 1rem; }
    .order-details .game-infos .row pre {
      margin-bottom: 0;
      color: inherit; }
  .order-details .game-infos .info-group {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 4px; }
    .order-details .game-infos .info-group .label {
      margin-top: 0; }
