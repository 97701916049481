.spinner-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 100; }
  .spinner-wrapper .fa {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    margin-top: -35px !important;
    font-size: 50px; }

.loading-spinner {
  flex: 1 1; }
