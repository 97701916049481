.page-container .card {
  flex: 1 1; }

.data-table .customer-status .btn {
  display: none; }
  .data-table .customer-status .btn.disabled {
    display: block;
    cursor: default; }

.data-table .highlight .customer-status .btn {
  display: block; }
  .data-table .highlight .customer-status .btn.disabled {
    display: none; }
  .data-table .highlight .customer-status .btn .spinner-grow {
    display: none; }

.data-table .highlight.working .customer-status .btn .spinner-grow {
  display: inline-block; }
