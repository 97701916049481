.summary-report-page .lang-summary-table {
  margin-top: 1rem;
  border: 1px solid #d5dbdc; }
  .summary-report-page .lang-summary-table ul {
    padding-left: 0;
    margin-bottom: 0; }
  .summary-report-page .lang-summary-table li div {
    display: inline-block; }
  .summary-report-page .lang-summary-table .table-header {
    color: #6a7d80;
    font-weight: 600;
    overflow: hidden;
    border-bottom: 2px solid #364041; }
    .summary-report-page .lang-summary-table .table-header .cell {
      float: left;
      height: 100%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-weight: 700; }
  .summary-report-page .lang-summary-table .body {
    line-height: 40px; }
    .summary-report-page .lang-summary-table .body li {
      height: 40px;
      line-height: 40px; }
    .summary-report-page .lang-summary-table .body .lang-summary-item:nth-child(odd) {
      background-color: #e6e9ea; }
    .summary-report-page .lang-summary-table .body .lang-summary-item:nth-child(even) {
      background-color: #fff; }
    .summary-report-page .lang-summary-table .body .cell {
      font-size: 0.9rem; }
  .summary-report-page .lang-summary-table .note {
    width: 42% !important;
    text-align: left !important;
    padding-left: 0.5rem; }
  .summary-report-page .lang-summary-table .date {
    width: 10%; }
  .summary-report-page .lang-summary-table .lang {
    width: 10%; }
  .summary-report-page .lang-summary-table .count {
    width: 16%; }
  .summary-report-page .lang-summary-table .service {
    width: 12%; }
  .summary-report-page .lang-summary-table .price {
    width: 10%; }
  .summary-report-page .lang-summary-table .cell {
    text-align: left;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: 1px solid #fff; }
    .summary-report-page .lang-summary-table .cell.right {
      text-align: right; }
    .summary-report-page .lang-summary-table .cell .icon {
      float: left;
      margin-left: 0.5rem; }
  .summary-report-page .lang-summary-table .cell:first-child,
  .summary-report-page .lang-summary-table .cell:last-child {
    border-right: none; }
